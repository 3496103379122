import { useMemo } from 'react';
import styled from 'styled-components';

import { Dimensions, Metrics } from 'components/insights/constants';
import ChartContainer from 'components/insights/layout/details/ChartContainer';
import { ViewByValues } from 'components/insights/layout/details/helpers/dimensionsUtils';
import { generateExtendedChartConfig } from 'components/insights/layout/details/helpers/responseTimeUtils';
import MetricDetailBreadcrumbs from 'components/insights/layout/details/MetricDetailBreadcrumbs';
import MetricDetailContainer from 'components/insights/layout/details/MetricDetailContainer';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import MetricHeaderValue from 'components/insights/layout/details/MetricHeaderValue';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { formatDuration } from 'components/shared/charts/helpers';
import Histogram from 'components/shared/charts/Histogram';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

type Props = {
  data: AnalyticsResponseData;
  displayHeaders: { [key: string]: string };
  isPriorityNote: boolean;
  loading: boolean;
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
  responseTimeLabel: string;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimensions: Dimensions[];
};

const ResponseTimeChart = (props: Props) => {
  const {
    data,
    displayHeaders,
    isPriorityNote,
    loading,
    onSelectedDimensionsChange,
    responseTimeLabel,
    rollups,
    selectedDimensions,
  } = props;
  const { profile } = useProfile();
  const isMobile = useIsMobile();

  const countLabel = isPriorityNote ? 'Priorities' : 'Escalations';

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.getDimensionValuesByIndex(0), [data.dimensions]);

  const responseTimeRollup = rollups.overall.metrics.getCurrentDataPointByIndex(0, 0);
  const priorResponseTimeRollup = rollups.overall.metrics.getPriorDataPointByIndex(0, 0);
  const escalationAckIdCountRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.ID_COUNT, 0);

  const responseTimes = data.metrics.getCurrentSeriesByIndex(0);

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories, data, rollups }),
    [categories, data, rollups]
  );

  const responseTimeViewBySelectOptions = [
    {
      label: displayHeaders[profile.clientType],
      value: ViewByValues.owner,
    },
  ];
  const priorityNoteViewBySelectOptions = [
    {
      label: `${selectedGroupType?.displayName}`,
      value: ViewByValues.provider,
    },
  ];

  return (
    <MetricDetailContainer
      loading={loading}
      hasData={!!responseTimes?.length}
      header={
        <MetricDetailHeader>
          <MetricDetailHeader.Row>
            <MetricHeaderValue
              label={responseTimeLabel}
              hasData={!!responseTimes?.length}
              loading={loading}
              value={
                <>
                  {responseTimeRollup == null ? <>&mdash;</> : formatDuration(responseTimeRollup, true)}
                  <MetricDiffBadge
                    currentValue={responseTimeRollup}
                    priorValue={priorResponseTimeRollup}
                    comparator={Comparator.LESS_THAN}
                    formatter={(val) => `${formatDuration(val, true)}`}
                  />
                </>
              }
            />
            <Separator />
            <MetricHeaderValue
              label={countLabel}
              loading={loading}
              value={escalationAckIdCountRollup}
              hasData={!!responseTimes?.length}
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimensions={selectedDimensions}
                groupType={selectedGroupType}
                additionalOptions={isPriorityNote ? priorityNoteViewBySelectOptions : responseTimeViewBySelectOptions}
                excludeProviderGroupOption
                excludeProviderCompanyOption={!isPriorityNote || profile.isPostAcute}
                onChange={(selectedOption) => {
                  onSelectedDimensionsChange(selectedOption.value);
                }}
              />
            )}
          </MetricDetailHeader.Row>
          {!isMobile && <MetricDetailBreadcrumbs onSelectedDimensionsChange={onSelectedDimensionsChange} />}
        </MetricDetailHeader>
      }>
      <ChartContainer>
        <Histogram config={chartConfig} />
      </ChartContainer>
    </MetricDetailContainer>
  );
};

export default ResponseTimeChart;

const Separator = styled.div<{ height?: string }>`
  height: ${({ height }) => height || '100%'};
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;
