import { useMemo, useState } from 'react';

import { Dimensions, Metrics, SortOrder, Sources } from 'components/insights/constants';
import { useInsightsContext } from 'components/insights/InsightsContainer';
import { ViewByValues } from 'components/insights/layout/details/helpers/dimensionsUtils';
import MetricBackArrow from 'components/insights/layout/details/MetricBackArrow';
import MetricDetailControlBar from 'components/insights/layout/details/MetricDetailControlBar';
import Separator from 'components/insights/layout/Separator';
import useAnalytics from 'components/insights/query/useAnalytics';
import { useInsightsStore } from 'stores/insightsStore';

import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';

import ReadmissionRateChart from './ReadmissionRateChart';
import ReadmissionRateDataTable from './ReadmissionRateDataTable';
import ReadmittedPatientsDataTable from './ReadmittedPatientsDataTable';

const ReadmissionRateDetail = () => {
  const { profile } = useInsightsContext();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const [selectedDimensions, setSelectedDimensions] = useState<Dimensions[]>(
    profile.isAcute ? ViewByValues.providerClient : ViewByValues.group
  );
  const requestParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DISCHARGES,
      dimensions: selectedDimensions,
      metrics: [Metrics.ID_COUNT, Metrics.READMISSIONS_SUM, Metrics.READMISSION_RATE],
      sortBy: `${Metrics.READMISSION_RATE} ${SortOrder.DESC}`,
      rollups: true,
    }),
    [selectedDimensions]
  );

  const analyticsResponse = useAnalytics(requestParams);

  return (
    <>
      <MetricDetailControlBar>
        <MetricBackArrow />
        <Separator height='20px' />
        <ComparePriorPeriodToggle selectedLegendColor='#DC2853' priorLegendColor='#E8ADBE' />
      </MetricDetailControlBar>
      <ReadmissionRateChart
        {...analyticsResponse}
        onSelectedDimensionsChange={setSelectedDimensions}
        selectedDimensions={selectedDimensions}
      />
      <ReadmissionRateDataTable
        {...analyticsResponse}
        requestParams={requestParams}
        onSelectedDimensionsChange={setSelectedDimensions}
        selectedDimensions={selectedDimensions}
        selectedGroupType={selectedGroupType}
      />
      <ReadmittedPatientsDataTable onSelectedDimensionsChange={setSelectedDimensions} />
    </>
  );
};

export default ReadmissionRateDetail;
