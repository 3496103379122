import { useMemo } from 'react';

import { Dimensions, Metrics } from 'components/insights/constants';
import { ViewByValues } from 'components/insights/layout/details/helpers/dimensionsUtils';
import MetricDetailBreadcrumbs from 'components/insights/layout/details/MetricDetailBreadcrumbs';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import Histogram from 'components/shared/charts/Histogram';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import ChartContainer from '../ChartContainer';
import { generateExtendedChartConfig } from '../helpers/alosUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import MetricHeaderValue from '../MetricHeaderValue';
import ViewBySelect from '../ViewBySelect';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimensions: Dimensions[];
};

const AverageLengthOfStayChart = (props: Props) => {
  const { data, loading, onSelectedDimensionsChange, rollups, selectedDimensions } = props;
  const isMobile = useIsMobile();
  const { profile } = useProfile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.getDimensionValuesByIndex(0), [data.dimensions]);

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories, data, rollups }),
    [categories, data, rollups]
  );

  const lengthOfStayAverageRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.LENGTH_OF_STAY_AVERAGE, 0);
  const priorLengthOfStayAverageRollup = rollups.overall.metrics.getPriorDataPoint(Metrics.LENGTH_OF_STAY_AVERAGE, 0);

  const lengthOfStayAverages = data.metrics.getCurrentSeries(Metrics.LENGTH_OF_STAY_AVERAGE);

  return (
    <MetricDetailContainer
      loading={loading}
      hasData={!!lengthOfStayAverages?.length}
      header={
        <MetricDetailHeader>
          <MetricDetailHeader.Row>
            <MetricHeaderValue
              label='Average Length of Stay'
              loading={loading}
              hasData={!!lengthOfStayAverages?.length}
              value={
                <>
                  {`${lengthOfStayAverageRollup?.toFixed(1) ?? '—'} days`}
                  <MetricDiffBadge
                    currentValue={lengthOfStayAverageRollup}
                    priorValue={priorLengthOfStayAverageRollup}
                    comparator={Comparator.LESS_THAN}
                    formatter={(val) => `${val.toFixed(1)}`}
                  />
                </>
              }
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimensions={selectedDimensions}
                groupType={selectedGroupType}
                additionalOptions={[{ label: 'Discharged To', value: ViewByValues.reason }]}
                onChange={(selectedOption) => {
                  onSelectedDimensionsChange(selectedOption.value);
                }}
                excludeProviderCompanyOption={profile.isPostAcute}
              />
            )}
          </MetricDetailHeader.Row>
          {!isMobile && <MetricDetailBreadcrumbs onSelectedDimensionsChange={onSelectedDimensionsChange} />}
        </MetricDetailHeader>
      }>
      <ChartContainer>
        <Histogram config={chartConfig} />
      </ChartContainer>
    </MetricDetailContainer>
  );
};

export default AverageLengthOfStayChart;
