import { ForwardedRef, forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { camelCase } from 'lodash';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import PatientStatusUpdateModal from 'components/modals/PatientStatusUpdateModal';
import MoreContentRequester from 'components/MoreContentRequester';
import { portfolioLaneSortingOptions } from 'components/portfolio/helpers/filters';
import PortfolioLaneEmptyState from 'components/portfolio/PortfolioLaneEmptyState';
import Menu from 'components/shared/menu';
import { PatientState } from 'constants/filterKeysConstants';
import Episode from 'models/Episode';
import Profile from 'models/Profile';
import RehabState, { RehabStateApiName, RehabStateName, RehabStateOptions } from 'models/RehabState';
import { PortfolioFilterValue } from 'models/userPreferences/PortfolioFilter';
import { showLocationEpisode } from 'services/api/locationEpisodes';
import { indexPortfolioLane, PortfolioColumn, portfolioQueryKeys } from 'services/api/portfolio';
import { upsertPortfolioFilter } from 'services/api/preferences';
import { transitionRules } from 'services/transitionrules';
import { PortfolioFilterState } from 'stores/portfolioStore';
import { PortfolioSorts, PortfolioSortValue } from 'stores/portfolioStore';
import { usePortfolioActions, usePortfolioStore } from 'stores/portfolioStore';
import { useToastActions } from 'stores/toastStore';
import { colors } from 'styles/theme/colors';
import SortIcon from 'svg/SortIcon';

import AdmittedAtModal from '../modals/AdmittedAtModal';
import DischargeModal from '../modals/DischargeModal';

import PatientCard, { SkeletonPatientCard } from './PortfolioCard';

type PortfolioLaneType = {
  locationType?: string;
  patientState?: PatientState;
  debouncedSearch: string;
  currentRehabState: RehabStateName;
  profile: Profile;
  rehabStates: null | RehabStateOptions[];
  filters: PortfolioFilterState;
  initialColumnData?: PortfolioColumn;
  loadingPortfolio: boolean;
};

type DraggableCard = {
  type: string;
  episode: Episode;
  locationEpisodeId: string;
  rehabState: RehabState;
};

export const PortfolioLane = forwardRef((props: PortfolioLaneType, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    debouncedSearch,
    currentRehabState,
    profile,
    rehabStates,
    filters,
    patientState,
    locationType,
    initialColumnData,
    loadingPortfolio,
  } = props;

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const { mutate } = useMutation({
    mutationFn: upsertPortfolioFilter,
  });

  const sorts = usePortfolioStore((state) => state.sorts);
  const tab = usePortfolioStore((state) => state.selectedTab);
  const { setSort } = usePortfolioActions();

  const rehabStateApiName = camelCase(currentRehabState) as RehabStateApiName;

  const queryClient = useQueryClient();
  const default_sort =
    currentRehabState.toLowerCase() == 'in treatment'
      ? { key: rehabStateApiName, attributeName: 'lengthOfStay', direction: 'desc' }
      : { key: rehabStateApiName, attributeName: 'patientName', direction: 'asc' };

  const sort = sorts?.[rehabStateApiName] ?? default_sort;

  const [sortingDifferentThanInitial, setSortingDifferentThanInitial] = useState(false);
  const queryParams = useMemo(
    () => ({
      locationType: locationType,
      patientState: patientState,
      search: debouncedSearch,
      currentRehabState: currentRehabState,
      sortBy: `${sort.attributeName} ${sort.direction},patientName asc`,
      filters,
    }),
    [locationType, patientState, debouncedSearch, currentRehabState, sort, filters]
  );

  // if sorting does not change, lane uses initial data and will query subsequent pages starting at page 2
  // if sorting changes, lane controls itself starting at page 1
  const entries = useInfiniteQuery({
    queryKey: [
      ...portfolioQueryKeys.lane(currentRehabState!),
      profile?.actingClientId,
      queryParams,
      sortingDifferentThanInitial,
      initialColumnData,
    ],
    queryFn: ({ pageParam, signal }) => indexPortfolioLane({ ...queryParams, page: pageParam, pageSize: 25 }, signal),
    initialPageParam: sortingDifferentThanInitial ? 1 : 2,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    initialData: initialColumnData ? { pages: [initialColumnData], pageParams: [1] } : undefined,
    enabled: sortingDifferentThanInitial,
  });

  const { addToast } = useToastActions();

  const [draggedPatient, setDraggedPatient] = useState<{
    locationEpisodeId: string;
    episode: Episode;
    intendedRehabState: RehabStateApiName;
  }>();

  const rehabStateToInvalidate = useRef<string>();

  const { data: locationEpisode } = useQuery({
    queryKey: ['locationEpisode', draggedPatient?.locationEpisodeId],
    queryFn: () =>
      showLocationEpisode({
        id: draggedPatient?.locationEpisodeId ?? '',
        include: 'question_templates,owner.client.group_types,owner.client.enabled_flags,reviews,group',
      }),
    enabled: !!draggedPatient?.locationEpisodeId,
  });

  const [{ dragItem, dragItemType, isOver }, dropZone] = useDrop({
    accept: 'card',
    drop: (item: DraggableCard) => {
      if (item.rehabState.state === currentRehabState) return item;
      const result = canChangeState(item);
      if (result.valid) {
        rehabStateToInvalidate.current = item.rehabState.apiName;
        setDraggedPatient({
          intendedRehabState: rehabStateApiName,
          locationEpisodeId: item.locationEpisodeId,
          episode: item.episode,
        });
      } else {
        addToast({ text: result.message ?? 'Something went wrong.' });
      }
      return item;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      dragItem: monitor.getItem(),
      dragItemType: monitor.getItemType(),
    }),
  });

  const canChangeState = (item: any) => {
    if (!item) return { valid: false };
    const sourceState: RehabStateName = item.rehabState.state;
    const destinationState = currentRehabState;
    const acceptRules = transitionRules[sourceState].accepts;
    const rejectRules = transitionRules[sourceState].rejects;
    const permission = transitionRules[destinationState].permission;
    if (permission.permission ? !profile?.permissions[permission.permission] : false) {
      return {
        valid: false,
        message: permission.message,
      };
    }
    if (acceptRules.includes(destinationState)) {
      return {
        valid: true,
        message: '',
      };
    }
    const rejectRule = rejectRules.find((x) => x.states.includes(destinationState));
    return {
      valid: false,
      message: rejectRule?.message,
    };
  };

  const invalidate = useCallback(() => {
    if (rehabStateToInvalidate.current) {
      queryClient.invalidateQueries({
        queryKey: ['portfolio'],
      });
    }
  }, [queryClient]);

  const handleSetSort = (sort: PortfolioSortValue) => {
    scrollRef.current?.scrollTo(0, 0);
    setSort(rehabStateApiName, sort);

    setSortingDifferentThanInitial(true);

    const updatedSorts: PortfolioSorts = { ...sorts, [rehabStateApiName]: sort };

    mutate({
      clientId: profile?.actingClient?.id as string,
      value: {
        ...filters,
        sorts: updatedSorts,
        providerType: tab || locationType,
      } as PortfolioFilterValue,
    });
  };

  const cards = entries.data?.pages.flatMap((page) => page.data) ?? [];

  const totalRecords = sortingDifferentThanInitial
    ? entries.data?.pages?.[0]?.meta?.totalRecords || 0
    : initialColumnData?.meta.totalRecords;

  const showSkeleton = !profile || entries.isLoading || loadingPortfolio;

  // PDD sort option is only shown in Admission and In Treatment lanes.
  // For acute users, display the sort option if client has PDD enabled for that provider type.
  // Always displayed for post acute users
  const showSortByProjectedDischargeDate = (rehabState: RehabStateApiName) =>
    [RehabStateApiName.Admission, RehabStateApiName.InTreatment].includes(rehabState) &&
    (!!profile?.isPostAcute ||
      !!profile?.actingClient?.configForGroupType(locationType as string)?.projectedDischarge.enabled);

  const sortingOptions = portfolioLaneSortingOptions(
    rehabStateApiName,
    showSortByProjectedDischargeDate(rehabStateApiName)
  );

  return (
    <SwimlaneColumn
      ref={ref}
      $dragValid={dragItemType == 'card' && canChangeState(dragItem).valid}
      $isOver={isOver}
      data-cy={`lane${currentRehabState.replace(/\s/g, '')}`}>
      {draggedPatient?.intendedRehabState === RehabStateApiName.InTreatment && locationEpisode && (
        <PatientStatusUpdateModal
          profile={profile}
          title='Start Treatment'
          setShow={() => setDraggedPatient(undefined)}
          locationEpisode={locationEpisode}
          patientName={locationEpisode?.patient.name ?? ''}
          invalidateData={invalidate}
        />
      )}
      {draggedPatient?.intendedRehabState === RehabStateApiName.Admission && (
        <AdmittedAtModal
          setShow={() => setDraggedPatient(undefined)}
          invalidateData={invalidate}
          rehabStates={rehabStates}
          locationEpisodeId={draggedPatient?.locationEpisodeId}
          patientName={draggedPatient?.episode?.patient?.name ?? ''}
        />
      )}
      {draggedPatient?.intendedRehabState === RehabStateApiName.Discharged && locationEpisode && (
        <DischargeModal
          invalidateData={invalidate}
          episode={draggedPatient?.episode}
          locationEpisode={locationEpisode}
          setShow={() => setDraggedPatient(undefined)}
          patientName={draggedPatient?.episode?.patient?.name ?? ''}
        />
      )}
      <SwimlaneColumnHeader>
        <RehabStateText>{currentRehabState}</RehabStateText>
        <Menu>
          <Menu.Trigger>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer' }} role='button'>
              <SortIcon color={colors.black50} />
              <div>{totalRecords}</div>
            </div>
          </Menu.Trigger>
          <Menu.Content position='right' style={{ maxHeight: 'none' }}>
            {sortingOptions.map((option) => (
              <Menu.Item
                key={option.rehabStateApiName}
                $active={sort.attributeName === option.attributeName && sort.direction === option.direction}
                onClick={() =>
                  handleSetSort({
                    key: option.rehabStateApiName,
                    attributeName: option.attributeName,
                    direction: option.direction as 'asc' | 'desc',
                    rehabStateApiName: option.rehabStateApiName,
                  })
                }>
                {option.label}
              </Menu.Item>
            ))}
          </Menu.Content>
        </Menu>
      </SwimlaneColumnHeader>
      <SwimlaneColumnItems
        ref={(el) => {
          dropZone(el);
          scrollRef.current = el;
        }}>
        {showSkeleton ? (
          <>
            <SkeletonPatientCard />
            <SkeletonPatientCard />
            <SkeletonPatientCard />
          </>
        ) : (
          <>
            {cards.length == 0 ? (
              <PortfolioLaneEmptyState rehabStateName={currentRehabState} />
            ) : (
              cards.map((x) => (
                <PatientCard
                  key={x.info.locationEpisodeId}
                  episode={x.episode}
                  info={x.info}
                  profile={profile}
                  invalidateColumn={invalidate}
                />
              ))
            )}
            <MoreContentRequester resource={entries} />
          </>
        )}
      </SwimlaneColumnItems>
    </SwimlaneColumn>
  );
});

const SwimlaneColumn = styled.div<{ $dragValid: boolean; $isOver: boolean }>`
  scroll-snap-align: center center;
  scroll-snap-stop: always;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 100%;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  flex-direction: column;
  padding: 15px 0px 12px 12px;
  border: ${({ $dragValid }) => ($dragValid ? '2px dotted ' + colors.primaryBlue : '2px solid transparent')};
  background-color: ${({ $dragValid, $isOver }) =>
    $dragValid && $isOver ? 'rgba(50, 83, 239, 0.1)' : 'rgb(226, 228, 230)'};

  @media ${({ theme }) => theme.devices.desktop} {
    min-width: 0;
  }
`;
const SwimlaneColumnHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  padding-bottom: 24px;
  padding-right: 10px;
`;

const SwimlaneColumnItems = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow-y: scroll;
  padding-right: 4px;

  &::-webkit-scrollbar {
    height: 0.5em;
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.scrollbarGray};
    border-radius: 5pt;
  }
`;

const RehabStateText = styled.p`
  flex: 1;
`;
