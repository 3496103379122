import { useMemo, useState } from 'react';

import { Dimensions, Metrics, SortOrder, Sources } from 'components/insights/constants';
import { useInsightsContext } from 'components/insights/InsightsContainer';
import { ViewByValues } from 'components/insights/layout/details/helpers/dimensionsUtils';
import MetricBackArrow from 'components/insights/layout/details/MetricBackArrow';
import MetricDetailControlBar from 'components/insights/layout/details/MetricDetailControlBar';
import Separator from 'components/insights/layout/Separator';
import useAnalytics from 'components/insights/query/useAnalytics';

import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';

import OlioEngagementScoreChart from './OlioEngagementScoreChart';
import OlioEngagementScoreDataTable from './OlioEngagementScoreDataTable';

const OlioEngagementScoreDetail = () => {
  const { profile } = useInsightsContext();

  const [selectedDimensions, setSelectedDimensions] = useState<Dimensions[]>(
    profile.isAcute ? ViewByValues.providerClient : ViewByValues.group
  );

  const requestParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DAYS,
      dimensions: selectedDimensions,
      metrics: [Metrics.OLIO_ENGAGEMENT_SCORE, Metrics.LOCATION_EPISODE_ID_COUNT],
      sortBy: `${Metrics.OLIO_ENGAGEMENT_SCORE} ${SortOrder.DESC}`,
      rollups: true,
    }),
    [selectedDimensions]
  );

  const analyticsResponse = useAnalytics(requestParams);

  return (
    <>
      <MetricDetailControlBar>
        <MetricBackArrow />
        <Separator height='20px' />
        <ComparePriorPeriodToggle />
      </MetricDetailControlBar>
      <OlioEngagementScoreChart
        {...analyticsResponse}
        onSelectedDimensionsChange={setSelectedDimensions}
        selectedDimensions={selectedDimensions}
      />
      <OlioEngagementScoreDataTable
        {...analyticsResponse}
        requestParams={requestParams}
        onSelectedDimensionsChange={setSelectedDimensions}
        selectedDimensions={selectedDimensions}
      />
    </>
  );
};

export default OlioEngagementScoreDetail;
