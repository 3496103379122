import { useMemo, useState } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Dimensions, Metrics } from 'components/insights/constants';
import { DrillableArrow, DrillableItem } from 'components/insights/layout/details/drillableTableComponents';
import { useTableConfig } from 'components/insights/layout/details/helpers/dimensionsUtils';
import useItemDrilldown from 'components/insights/layout/details/helpers/hooks/useItemDrilldown';
import MetricDetailBreadcrumbs from 'components/insights/layout/details/MetricDetailBreadcrumbs';
import TableHeader from 'components/insights/layout/details/TableHeader';
import TableTitle from 'components/insights/layout/details/TableTitle';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { AnalyticsQueryParams } from 'components/insights/query/useAnalyticsInfiniteQuery';
import DataTable from 'components/shared/DataTable';
import { useProfile } from 'context/profile';
import GroupType from 'models/GroupType';

import ApiExport from '../ApiExport';
import { CategoryRow } from '../helpers/utils';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import TableContainer from '../TableContainer';

export type ReadmissionRateTableRow = CategoryRow & {
  readmissions: number | null;
  readmissionRate: { current: number | null; prior: number | null };
};

type ReadmissionRateDataTableProps = {
  data: AnalyticsResponseData;
  loading: boolean;
  requestParams: AnalyticsQueryParams;
  rollups: AnalyticsResponseRollupCollection;
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
  selectedDimensions: Dimensions[];
  selectedGroupType: GroupType;
};

const ReadmissionRateDataTable = (props: ReadmissionRateDataTableProps) => {
  const { data, loading, requestParams, rollups, onSelectedDimensionsChange, selectedDimensions, selectedGroupType } =
    props;

  const { profile } = useProfile();

  const { tableHeader, tableItemFilterKey, subDimensions } = useTableConfig(
    selectedDimensions[0],
    selectedGroupType,
    profile.isPostAcute
  );

  const handleItemDrilldown = useItemDrilldown({
    series: data.dimensions.series,
    tableHeader,
    tableItemFilterKey,
    selectedDimensions,
    onSelectedDimensionsChange,
    subDimensions,
  });

  const [hoveredRowId, setHoveredRowId] = useState('');

  const categories = useMemo(() => data.dimensions.getCurrentDimensionValuesByIndex(0), [data.dimensions]);

  const dischargesTotal = useMemo(
    () => rollups.overall.metrics.getCurrentDataPoint(Metrics.ID_COUNT, 0),
    [rollups.overall.metrics]
  );
  const readmissionsTotal = useMemo(
    () => rollups.overall.metrics.getCurrentDataPoint(Metrics.READMISSIONS_SUM, 0),
    [rollups.overall.metrics]
  );
  const readmissionRate = useMemo(
    () => rollups.overall.metrics.getCurrentDataPoint(Metrics.READMISSION_RATE, 0),
    [rollups.overall.metrics]
  );

  const dischargeCounts = useMemo(() => data.metrics.getCurrentSeries(Metrics.ID_COUNT), [data.metrics]);
  const readmissionsSums = useMemo(() => data.metrics.getCurrentSeries(Metrics.READMISSIONS_SUM), [data.metrics]);
  const readmissionRates = useMemo(() => data.metrics.getCurrentSeries(Metrics.READMISSION_RATE), [data.metrics]);
  const priorReadmissionRates = useMemo(() => data.metrics.getPriorSeries(Metrics.READMISSION_RATE), [data.metrics]);

  const tableData = useMemo<ReadmissionRateTableRow[]>(() => {
    return categories.map((categoryName, i) => ({
      categoryName,
      readmissions: readmissionsSums[i],
      discharges: dischargeCounts[i],
      readmissionRate: {
        current: readmissionRates[i],
        prior: priorReadmissionRates[i],
      },
    }));
  }, [categories, dischargeCounts, readmissionsSums, readmissionRates, priorReadmissionRates]);

  const columnHelper = useMemo(() => createColumnHelper<ReadmissionRateTableRow>(), []);
  const columns = useMemo<ColumnDef<ReadmissionRateTableRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: tableHeader,
        cell: (cellData) => (
          <DrillableItem drillable={!!subDimensions} isRowHovered={hoveredRowId === cellData.row.id}>
            {cellData.getValue()}
          </DrillableItem>
        ),
      }),
      columnHelper.accessor('readmissions', {
        header: 'Readmissions',
        cell: (data) => data.getValue() ?? <>&mdash;</>,
        footer: () => `${readmissionsTotal ?? '—'} (Total)`,
      }),
      columnHelper.accessor('discharges', {
        header: 'Discharges',
        cell: (data) => data.getValue() ?? <>&mdash;</>,
        footer: () => `${dischargesTotal ?? '—'} (Total)`,
      }),
      columnHelper.accessor('readmissionRate', {
        header: 'Readmission Rate',
        cell: (data) => {
          return (
            <>
              <p>{data.getValue().current == null ? <>&mdash;</> : `${data.getValue().current}%`}</p>{' '}
              <MetricDiffBadge
                currentValue={data.getValue().current}
                priorValue={data.getValue().prior}
                comparator={Comparator.LESS_THAN}
                formatter={(val) => `${val.toFixed(1)}%`}
              />
              {subDimensions && hoveredRowId === data.row.id && <DrillableArrow width={9} height={9} />}
            </>
          );
        },
        footer: () => `${readmissionRate?.toFixed(1) ?? '—'}% (Avg)`,
      }),
    ],
    [columnHelper, tableHeader, subDimensions, hoveredRowId, readmissionsTotal, dischargesTotal, readmissionRate]
  );

  const desktopOnlyColumns = ['readmissions', 'discharges'];

  const defaultSort = [
    {
      id: 'readmissionRate',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableHeader>
        <TableHeader.Row>
          <TableTitle>Readmission Rate Overview</TableTitle>
          <ApiExport
            disabled={!tableData.length}
            show={!loading}
            params={requestParams}
            filename='readmission-rate-export'
          />
        </TableHeader.Row>
        <MetricDetailBreadcrumbs onSelectedDimensionsChange={onSelectedDimensionsChange} />
      </TableHeader>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        loading={loading}
        drillable={!!subDimensions}
        setHoveredRowId={setHoveredRowId}
        handleItemDrilldown={handleItemDrilldown}
      />
    </TableContainer>
  );
};

export default ReadmissionRateDataTable;
