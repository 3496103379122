import { useMemo } from 'react';
import styled from 'styled-components';

import { useMutation } from '@tanstack/react-query';

import FilterBar from 'components/shared/filters/FilterBar';
import { FilterSection } from 'components/shared/filters/FilterSlideout';
import DatePicker from 'components/shared/form/DatePicker';
import { END_DATE, START_DATE } from 'constants/filterKeysConstants';
import GroupType from 'models/GroupType';
import { upsertInsightsFilter } from 'services/api/preferences';
import { InsightsFilterState as Filters, useInsightsActions, useInsightsStore } from 'stores/insightsStore';

import { useInsightsContext } from '../InsightsContainer';
import ProviderTypeDropdown from '../ProviderTypeDropdown';

import DatePickerShortcuts from './DatePickerShortcuts';

type InsightsFilterBarProps = {
  activeFilters: Filters;
  filterSections: FilterSection[];
};

const InsightsFilterBar = ({ filterSections, activeFilters }: InsightsFilterBarProps) => {
  const { profile } = useInsightsContext();
  const { selectedGroupType } = useInsightsStore();
  const { setBreadcrumbs, restoreInitialSnapshot, setSelectedFilters, setSelectedGroupType } = useInsightsActions();

  const startDate = activeFilters[START_DATE] as Date;
  const endDate = activeFilters[END_DATE] as Date;
  const maxDate = useMemo(() => new Date(), []);
  const minDate = useMemo(() => new Date('05/01/2020'), []);

  const { mutate } = useMutation({
    mutationFn: upsertInsightsFilter,
  });

  const handleChangeGroupType = (groupType: GroupType) => {
    setSelectedGroupType(groupType);

    restoreInitialSnapshot();

    if (groupType.apiName !== selectedGroupType?.apiName) {
      mutate({
        clientId: profile.actingClient.id,
        value: {
          providerType: groupType.apiName,
        },
      });
    }
  };

  const handleClearFilters = () => {
    Object.entries(activeFilters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        setSelectedFilters({ [key]: [] });
      }
    });

    setBreadcrumbs([]);
  };

  const DatePickerFooter = ({ dateInput }) => (
    <DatePickerShortcuts
      datePickerRef={dateInput}
      setDates={([startDate, endDate]) => setSelectedFilters({ [START_DATE]: startDate, [END_DATE]: endDate })}
    />
  );

  return (
    <FilterBar
      showSearchInput={false}
      applyFilters={(filters: Filters) => {
        setSelectedFilters(filters);
      }}
      filters={activeFilters}
      showResults={false}
      filterSections={filterSections}
      onClearFilters={handleClearFilters}
      title={
        <>
          {profile && selectedGroupType && (
            <ProviderTypeDropdown
              enabledProviderTypes={profile.enabledProviderTypes}
              key={selectedGroupType.id}
              initialSelectedProviderType={selectedGroupType}
              onChange={handleChangeGroupType}
            />
          )}
        </>
      }
      callToAction={
        <DatePickerContainer>
          <DatePicker
            data-gtm-id='insightsStartDateFilter'
            onChange={(val) => setSelectedFilters({ [START_DATE]: val as Date })}
            selected={startDate}
            maxDate={endDate}
            minDate={minDate}
            clearable={false}
            renderFooter={DatePickerFooter}
            popperPlacement='bottom-start'></DatePicker>

          <Emdash>&mdash;</Emdash>

          <DatePicker
            data-gtm-id='insightsEndDateFilter'
            onChange={(val) => setSelectedFilters({ [END_DATE]: val as Date })}
            selected={endDate}
            maxDate={maxDate}
            minDate={startDate}
            clearable={false}
            renderFooter={DatePickerFooter}
            popperPlacement='bottom-start'
          />
        </DatePickerContainer>
      }
    />
  );
};

export default InsightsFilterBar;

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    max-width: 160px;

    @media ${({ theme }) => theme.devices.desktop} {
      max-width: 140px;
    }
  }
`;

const Emdash = styled.div`
  margin: 0 8px;
`;
